import React from "react";

import {
    Edit,
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField,
    required,
} from "react-admin";

import {defaultStyle} from "../../../style";

const FooterHtmlEdit = (props) => {
    return (
        <Edit {...props} title="Изменить соц.сеть">
            <SimpleForm redirect={false}>
                <TextInput
                    label="HTML"
                    source="html"
                    style={defaultStyle}
                    validate={[required()]}
                />
            </SimpleForm>
        </Edit>
    );
};

export default FooterHtmlEdit;
