import React from "react";

import {
    Create,
    SimpleForm,
    TextInput,
    ImageInput,
    ImageField,
    required,
} from "react-admin";

import {defaultStyle} from "../../../style";

const FooterHtmlCreate = (props) => {
    return (
        <Create {...props} title="Произвольный html">
            <SimpleForm>
                <TextInput
                    label="HTML"
                    source="html"
                    style={defaultStyle}
                    validate={[required()]}
                />
            </SimpleForm>
        </Create>
    );
};

export default FooterHtmlCreate;
