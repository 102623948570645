import React from "react";
import RichTextInput from "ra-input-rich-text";

import myDataProfider from "../.././myDataProvider";

import {
    Create,
    SimpleForm,
    FormDataConsumer,
    BooleanInput,
    TextInput,
    ImageInput,
    ImageField,
    SelectInput,
    SelectArrayInput,
    required,
} from "react-admin";

import { defaultStyle } from "../../style";

const FeedbackCreate = (props) => {
    const [goods, setGoods] = React.useState([]);
    const [teachers, setTeachers] = React.useState([]);

    React.useEffect(() => {
        myDataProfider
            .getList("goods", {
                pagination: { page: 1 },
                sort: { order: "ASC" },
            })
            .then(({ data }) => {
                setGoods(data);
            });

        myDataProfider
            .getList("teachers", {
                pagination: { page: 1 },
                sort: { order: "ASC" },
            })
            .then(({ data }) => {
                setTeachers(data);
            });
    }, []);

    return (
        <Create {...props} title="Новый отзыв">
            <SimpleForm>
                <FormDataConsumer>
                    {({ formData }) => (
                        <>
                            <TextInput
                                source="name"
                                label="Имя отзыва"
                                validate={[required()]}
                                style={defaultStyle}
                            />
                            {goods.length ? (
                                <SelectInput
                                    label="Курс"
                                    source="courseId"
                                    choices={goods}
                                    optionValue="id"
                                    optionText="title"
                                    validate={[required()]}
                                    style={defaultStyle}
                                />
                            ) : null}
                            {teachers.length ? (
                                <SelectArrayInput
                                    label="Преподаватели"
                                    source="auth"
                                    choices={teachers}
                                    optionValue="id"
                                    optionText="name"
                                    validate={[required()]}
                                    style={defaultStyle}
                                />
                            ) : null}
                            <ImageInput
                                source="image"
                                label="Изображение (максимальный размер 2МБ)"
                                maxSize="2000000"
                                accept="image/*"
                                placeholder={<p>Перетащите файл сюда</p>}
                                validate={[required()]}
                                style={defaultStyle}
                            >
                                <ImageField source="src" />
                            </ImageInput>
                            
                            <BooleanInput
                                label="Вставить код плеера"
                                source="isCustomVideoCode"
                            />

                            {formData.isCustomVideoCode ? (
                                <TextInput
                                    source="videoCode"
                                    label="Код плеера"
                                    validate={[required()]}
                                    style={defaultStyle}
                                />
                            ) : (
                                <TextInput
                                    source="videoUrl"
                                    label="Ссылка на видео Ютуб"
                                    validate={[required()]}
                                    style={defaultStyle}
                                />
                            )}

                            <TextInput
                                source="description"
                                label="Описание"
                                validate={[required()]}
                                style={defaultStyle}
                            />
                        </>
                    )}
                </FormDataConsumer>
            </SimpleForm>
        </Create>
    );
};

export default FeedbackCreate;
