import React from "react";

import {
    List,
    Datagrid,
    TextField,
    UrlField,
    ImageField,
    EditButton,
} from "react-admin";

const FooterHtmlList = (props) => {
    return (
        <List
            {...props}
            pagination={false}
            title="Произвольный HTML"
            bulkActionButtons={false}
        >
            <Datagrid>
                <EditButton />
                <TextField label="ID" source="id" sortable={false} />
                <ImageField label="HTML" source="html" sortable={false} />
            </Datagrid>
        </List>
    );
};

export default FooterHtmlList;
